import { useNavigate } from "@remix-run/react";
import clsx from "clsx";
import { ReactNode } from "react";

//create Props
interface Props {
  children?: ReactNode;
  className?: string;
  subtitle?: string;
  segments?: {
    name: string;
  }[];
  subNav?: ReactNode;
  tabs?:
    | {
        name: string;
        href: string;
        icon: ReactNode;
      }[]
    | null;
  left?: ReactNode;
  navbarProps?: {
    large?: boolean;
  };
  leftClick?: () => void;
  right?: ReactNode;
  scrollEl?: any;
  tabBarProps?: {
    side?: "left" | "right";
    sideSize?: "1/4" | "1/3" | "1/2" | "2/3" | "3/4";
    mainSize?: "1/4" | "1/3" | "1/2" | "2/3" | "3/4";
    breakpointClass?: "sm" | "md" | "lg" | "xl" | "2xl";
    sider?: boolean;
  };
  relativeContent?: boolean;
}

export default function PageLayout({
  className,
  subtitle,
  segments,
  subNav,
  children,
  tabs,
  left,
  navbarProps = {},
  leftClick,
  right,
  scrollEl,
  tabBarProps,
  relativeContent = false,
}: Props) {
  const navigate = useNavigate();

  // let subNavBar = subNav ? subNav : null;
  // if (segments && segments.length > 0) {
  //   subNavBar = (
  //     <Segmented strong>
  //       {segments.map((segment) => (
  //         <SegmentedButton small strong active={segment.active}>
  //           {segment.name}
  //         </SegmentedButton>
  //       ))}
  //     </Segmented>
  //   );
  // }

  return (
    <>
      {/* <Navbar
        scrollEl={scrollEl}
        {...navbarProps}
        title={
          <Transition
            appear={true}
            show={true}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="truncate"
          >
            {title}{" "}
          </Transition>
        }
        subtitle={
          <Transition
            appear={true}
            show={true}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="truncate"
          >
            {subtitle}
          </Transition>
        }
        right={right}
        subnavbar={subNavBar}
        left={
          <Transition
            appear={true}
            show={true}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            className="h-full w-14"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            onClick={leftClick}
          >
            {left}
          </Transition>
        }
      /> */}
      {/* <Transition
        appear={true}
        show={true}
        enter={clsx("transition-opacity duration-300")}
        // entered={clsx(tabs && "pb-20")}
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={clsx("content", "flex flex-1 flex-col", className)}
      > */}
      <div className={clsx("content", "flex flex-col", className)}>{children}</div>
      {/* </Transition> */}
      {/* {tabs ? (
        <Tabbar
          labels={true}
          icons={true}
          className={clsx(
            "fixed bottom-0",
            tabBarProps?.sider
              ? `${tabBarProps.breakpointClass}:${tabBarProps.side}-0 ${tabBarProps.breakpointClass}:w-${tabBarProps.sideSize}`
              : tabBarProps
              ? `${tabBarProps?.breakpointClass}:${tabBarProps?.side}-${tabBarProps?.sideSize} ${tabBarProps.breakpointClass}:w-${tabBarProps.mainSize}`
              : ""
          )}
          translucent
        >
          {tabs.map((tab) => (
            <TabbarLink onClick={tab.onClick ? tab.onClick : () => navigate(tab.href)} key={tab.name} label={tab.name} icon={tab.icon} />
          ))}
        </Tabbar>
      ) : null} */}
    </>
  );
}
